import { resolveValue } from '@intlify/core-base'

import { i18nDefaults } from '~/i18n/i18n.defaults'
import enUS from '~/i18n/locales/en-US.json'

export default defineI18nConfig(() => ({
  domainLocale: ['en-US'],
  fallbackLocale: 'en-US',
  fallbackWarn: false,
  globalInjection: true,
  legacy: false,
  messageResolver: (translations: any, key) => {
    const normalizedKey = normalizeKey(key)
    return (
      translations[normalizedKey] || resolveValue(translations, normalizedKey)
    )
  },
  messages: {
    'en-US': {
      ...i18nDefaults,
      ...enUS,
    },
  },
  missingWarn: false,
}))
